<template>

<div id="404">
	
	<vue-headful title="404 - Page not found"/>
	
	<section class="content">
	
		<h1>Page not found 🧐</h1>
	
		<p class="intro_text">
		
			Ooops, there's nothing here.
		
		</p>
	
	</section>

</div>

</template>

<script>

	export default
	{
		
	}
	
</script>
